import React from "react";
import './blog.css'; 
import { useEffect } from "react";
import './bootstrap.min.css'; 
import './joke.css'; 

function It()
{
    useEffect(()=>{
        document.title = "Accessible IT staffing & recruitment consulting India |Pearlcon";
        document.querySelector('meta[name="description"]').setAttribute('content', 'Your meta description goes here.');

    })
    return(
        <>

        <div className="py-5" style={{backgroundColor:"#2054ae"}}>
            <div className="container-fluid">
            
            <div className="row g-4">
               
                
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div style={{paddingTop:"40px"}}>
                       <h1 style={{color:"#ffffff",fontSize:"40px"}}>Eminent Hirings for All Industries</h1>
                       <p style={{color:"#ffffff",fontSize:"20px"}}>For nearly 5+ years, Pearlcon has been eminently involved in the matching of qualified applicants with open positions in India & globe.</p>
                       <p style={{color:"#ffffff",fontSize:"20px"}}>Although we have assisted companies across numerous industries, these are our areas of expertise.</p>

                    </div>
                </div>
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div style={{float:"inline-end"}}>
                        <img className="img-fluid" src="img/it.png" alt="" />
                       
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div className="py-5">
            <div className="container-fluid">
            
            <div className="row g-4">
               
                
               
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div style={{float:"inline-end"}}>
                        <img className="img-fluid" src="img/girl.png" alt="" />
                       
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div>
                       <h1 className="text-end">IT Recruitment Made Planned And Easy</h1>
                       <p className="text-end">Pearlcon’s primary goals are based on solving your IT recruiting obstacles in a dynamic landscape, we have unique recruitment techniques and the team having extensive experience working for various companies across India. We locate and provide the most trustworthy staffs, both skilled and unskilled, for positions in IT, industrial, warehouse, driving, facilities management, construction, and logistics.</p>

                    </div>
                    <div className="row g-4">
                        <div className="col-lg-1 wow fadeInUp" data-wow-delay="0.5s">
                        <img className="img-fluid" style={{width:"50px"}} src="img/money1.png" alt="" />

                        </div>
                        <div className="col-lg-11 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="my-1">
                                <h4 className="text-start mt-6">Hiring Flexibility & Cost Efficiency</h4>
                            </div>
                            <div className="my-3">

                                <p>Get access to top tier talent with our IT staffing services with flexibility in terms of budget, requirement and timeliness. We collaborate closely with our customers on a monthly subscription basis, enabling them to grow their  teams at their own speed.</p>
                            </div>
                        </div>

                    </div>
                    <div className="row g-4">
                        <div className="col-lg-1 wow fadeInUp" data-wow-delay="0.5s">
                        <img className="img-fluid" style={{width:"50px"}} src="img/money2.png" alt="" />

                        </div>
                        <div className="col-lg-11 wow fadeInUp" data-wow-delay="0.5s">
                            <div className="my-1">
                                <h4 className="text-start mt-6">Reduce business risks & Liabilities</h4>
                            </div>    
                            <div className="my-3">
                                <p>We have lot of databases , strategies and resources that is keenly shaped for uplifting human resources potential in your company with cost-cutting and avoiding risks in business for improper manpower- hiring that doesn't stand out.</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </div>




  
        </>
    )
}
export default It;