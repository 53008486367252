import './App.css';
// import '../public/lib/owlcarousel/owl.carousel.js';

import Navbar from './components/navbar';
import Footer from './components/footer';
import About from './components/about';
import Header from './components/header';
import Home from './components/home';
import Contact from './components/contact';
import Blog from './components/blog';
import Searchjobs from './components/searchjobs';
import Recruitment from './components/recruitement';
import It from './components/it';
import Itchennai from './components/itchennai';
import Itpune from './components/itpune';
import Itsolution from './components/itsolution';
import Hrsolution from './components/hrsolution';
import Viewblog from './viewblog';

import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
function App() {
  return (
    <Router>
    <>
      <Header />
      <Navbar />
      <Routes>
        
        <Route exact path="/"  element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route exact path="/blogs-hr-it-consulting" element={<Blog />} />
        <Route path="/job-placements-in-india/:id" element={<Searchjobs />} />
        <Route path="/it-recruitment-solutions-in-india" element={<It />} />
        <Route path="/placement-agency-in-chennai" element={<Itchennai />} />
        <Route path="/placement-agency-in-pune" element={<Itpune />} />
        <Route path="/it-consulting-solutions-india" element={<Itsolution />} />
        <Route path="/hr-consulting-solutions-in-india" element={<Hrsolution />} />
        <Route path="/view/:id" element={<Viewblog />} />
        
        <Route path="/recruit" element={<Recruitment />} />
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      
      <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i
            className="bi bi-arrow-up"></i></a>
      <Footer />
    </>
  </Router>

  


  );
}

export default App;
