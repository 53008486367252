import React from "react"
import './bootstrap.min.css'; 
import './joke.css'; 




function Header(){
    return (
        <>
         <div className="container-fluid bg-primary text-white d-none d-lg-flex">
        <div className="container-fluid py-3">
            <div className="d-flex">
                <div className="d-flex">

                <a className="btn btn-sm-square btn-light text-primary rounded-circle ms-2" href="https://www.facebook.com/pearlcontechnologies" target="_blank"><i
                                className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-sm-square btn-light text-primary rounded-circle ms-2" href="https://twitter.com/Pearlcontech" target="_blank"><i
                                className="fab fa-twitter"></i></a>
                        <a className="btn btn-sm-square btn-light text-primary rounded-circle ms-2" href="https://in.linkedin.com/company/pearlcontechnologies" target="_blank"><i
                                className="fab fa-linkedin-in"></i></a>
                </div>
                <div className="ms-auto d-flex align-items-center">
                    <small className="ms-4"><i className="fa fa-map-marker-alt me-3"></i>Chennai and Pune</small>
                    <small className="ms-4"><i className="fa fa-envelope me-3"></i>opportunities@pearlcons.com</small>
                    <small className="ms-4"><i className="fa fa-phone-alt me-3"></i>9960662615</small>
                  
                </div>
            </div>
        </div>
    </div>
        </>
    )}
    export default Header