import React from "react"
import { NavLink } from 'react-router-dom';
import { useEffect } from "react";
import './bootstrap.min.css'; 
import './joke.css'; 

import { useLocation } from "react-router-dom";

function Home(){
    const location = useLocation();
    useEffect(() => {
        // Check if the current location is the home path ("/")
          // Update the document title
          document.title = "The Right Staffing & HR services | For MSMEs In India";
    
      });
    // updateTitle("Home Page - Your Company Name");
    const handleClick = () => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };
    return (
        <>
        <div className="container-fluid px-0 mb-5">
        <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img className="w-100 ban1" src="img/ban1.jpg" alt="Image" />
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row justify-content-start">
                                <div className="col-lg-9 text-start doop" style={{marginBottom:"160px"}}>
                                    <h1 className="text-white mb-5 animated slideInRight">Go Beyond Smart
                                        Recruitment & IT Consulting
                                    </h1>
                                    <p className="fs-4 text-white animated slideInRight">Fostering HR Transformation and implementing the suitable IT solutions for MSME's
                                        Growth</p>
                                        <NavLink to="/contact-us" className="btn btn-primary but rounded-pill py-3 px-5 animated slideInRight" activeclassName="active" onClick={handleClick}>
                                    Register a Vacancy</NavLink>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="carousel-item">
                    <img className="w-100" src="img/ban2.jpg" alt="Image" />
                    <div className="carousel-caption">
                        <div className="container">
                            <div className="row justify-content-end">
                                <div className="col-lg-7 text-end doop" style={{marginBottom:"160px"}}>
                                    <h1 className="text-white mb-5 animated slideInLeft ">
                                        Get the critical manpower at your ease
                                    </h1>
                                    <p className="fs-4 text-white animated slideInLeft">  
                                    Our experience and excellence in providing smart manpower at real affordability for every business
                                    </p>
                                    <NavLink to="/contact-us" className="btn btn-primary but rounded-pill py-3 px-5 animated slideInRight" activeclassName="active" onClick={handleClick}>
                                    Register a Vacancy</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </div>



    <div className="container-xxl py-3">
        <div className="container">
            <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s" >

                <h1 className="shake text-center mb-3" style={{color:"#00204a"}}>Empowering HR processes & IT consulting</h1>
                <p style={{color:"#020202"}}>Reap the advantages of working  and feel lucky to meet our well-experienced staff delivering the tech talents to employers in all sectors in India & globe. Our range of staffing services besides job consulting reflects the insights gained from years of attentive client feedback. Solve your hiring & IT tech challenges affordably. Ready to enquire about our costs?</p>
            </div>
            <div className="row g-4">
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item position-relative h-100">
                    <div className="content">
                        
                        <a href="https://unsplash.com/photos/HkTMcmlMOUQ" target="_blank">
                        <div className="content-overlay"></div>
                        <img className="img-fluid rounded"  src="img/a.jpg" alt="Icon" />

                        <div className="content-details fadeIn-bottom">
                            <h3 className="content-title">OUR STAFFING EXPOSURE</h3>
                            <p className="content-text">From the modest beginnings in early 2018, we have honed the best practices in manpower consulting & staffing approaches for all type of MSME's or businesses across the globe</p>
                        </div>
                        </a>
                    </div>
                      
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="service-item position-relative h-100">
                    <div className="content">
                        <a href="https://unsplash.com/photos/HkTMcmlMOUQ" target="_blank">
                        <div className="content-overlay"></div>
                        <img className="img-fluid rounded" src="img/b.jpg" alt="Icon" />


                        <div className="content-details fadeIn-bottom">
                            <h3 className="content-title">Our IT Exposure</h3>
                            <p className="content-text">We provide full-service custom software & app development solutions with cost-effective models for any business across the globe.   </p>
                        </div>
                        </a>
                    </div>
                      
                    </div>
                </div>
                
                
                
            </div>
        </div>
    </div>

  
    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-0 feature-row">
            <h1 className="shake text-center mb-3 py-4" style={{color:"#00204a"}}>Recruiting the right staff for you</h1>

                <div className="col-md-6 col-lg-3 wow fadeIn gh" data-wow-delay="0.1s">
                    <div className="feature-item border h-100 p-5">
                        <div className="btn-square  rounded-circle mb-4" style={{width: "100%"}}>
                            <img  className="img-fluid nr" src="img/icon/icon-1.png" alt="Icon" />
                        </div>
                        <div style={{height:"30px"}}>
                            <h5 className="mb-3 text-center">Permanent Job Consulting</h5>
                        </div>
                        <div style={{marginTop:"40px"}}>
                            <p style={{textAlign:"initial"}} className="mb-0">Qualities that are difficult to measure, such as initiative, reliability, and interpersonal skills, are critical markers of success. With help of these markers, we deliver your anticipated steady workforce for rapid expansion</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 wow fadeIn gh" data-wow-delay="0.3s">
                    <div className="feature-item border h-100 p-5 text-center">
                        <div className="btn-square rounded-circle mb-4" style={{width: "100%"}}>
                            <img className="img-fluid nr" src="img/icon/icon-2.png" alt="Icon" />
                        </div>
                        <div style={{height:"30px"}}>
                            <h5 className="mb-3">IT Recruitment Solutions</h5>
                        </div>
                        <div style={{marginTop:"40px"}}>
                            <p style={{textAlign:"initial"}}  className="mb-0">We have good exposure to IT recruitment strategies that builds your team with a strong basement for better growth. Thereby we assure enduring customer relationships & long term success.</p>
                        </div>
                    </div>  
                </div>
                <div className="col-md-6 col-lg-3 wow fadeIn gh" data-wow-delay="0.5s">
                    <div className="feature-item border h-100 p-5 text-center">
                        <div className="btn-square rounded-circle mb-4" style={{width: "100%"}}>
                            <img className="img-fluid nr" src="img/icon/icon-3.png" alt="Icon" />
                        </div>
                        <div style={{height:"30px"}}>
                            <h5 className="mb-3">Contract Staffing Services</h5>
                        </div>
                        <div style={{marginTop:"40px"}}>
                            <p style={{textAlign:"initial"}} className="mb-0">For this service, We have a wide network of consistent man-power resources and a flexible team to assist as well as pave the way for complete setup at stringent timelines.</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-lg-3 wow fadeIn gh" data-wow-delay="0.7s">
                    <div className="feature-item border h-100 p-5 text-center">
                        <div className="btn-square rounded-circle mb-4" style={{width: "100%"}}>
                            <img className="img-fluid nr" src="img/icon/icon-4.png" alt="Icon" />
                        </div>
                        <div style={{height:"30px"}}>
                            <h5 className="mb-3">Candidate Attraction</h5>
                        </div>
                        <div style={{marginTop:"40px"}}>
                            <p style={{textAlign:"initial"}} className="mb-0">Infusing the modern and traditional assessment approach , we are helping in candidate attraction and retention needs that's significant in the current competitive landscape.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div className="container-xxl py-3" style={{backgroundColor:"aliceblue"}}>
        <div className="container-fluid">
            <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s" >

            </div>
            <div className="row g-4">
                <div className="col-lg-6 col-md-6" data-wow-delay="0.1s">
                    <div className="h-100">
                        <div className="service-text rounded p-2 up-down">
                            <img className="img-fluid rounded"  src="img/how_new.png" alt="Icon" />
                        </div>
                        
                    </div>
                </div>
                <div className="col-lg-6 col-md-6" data-wow-delay="0.3s">
                    <div className="h-100">
                        <div>
                            <h2>Finding the right jobs for candidates</h2>
                        </div>
                        
                            <p>Pearlcon considers recruitment to be both a science and an art. We understand that the ideal candidate goes beyond possessing only the necessary hard skills and experience you seek.  While it can be difficult for candidates to take advantage of every chance, Pearlcon's HR services extend with ease because of our flexible staffing experience and expertise in the industry.</p>
                            <p >We are experiential job consultants in India having dynamically committed HR professionals who are equipped with requisite skills and tools to offer reliable staffing solutions in the quickest possible time for all MSMEs.</p>
                        <div  >
                            <h4 style={{float:"right"}}>Updating Opportunities</h4><br></br><br></br>
                            <p style={{textAlign:"end"}}>We don't leave job seekers in hassle, we continuously update the website and job portal with number of opportunities every week</p>
                        </div>
                        <div>
                            <h4 style={{float:"right"}}>Our Team Approach</h4><br></br><br></br>
                            <p style={{textAlign:"end"}}>We don't leave job seekers in hassle, we continuously update the website and job portal with number of opportunities every week</p>
                        </div>
                        <div>
                            <h4 style={{float:"right"}}>CV guidance & Talent network</h4><br></br><br></br>
                            <p style={{textAlign:"end"}}>Our recruiters are readily available to provide precise guidance in developing CV for experienced candidates and our talent pool network consists of large talented professionals, waiting to be hired.</p>
                        </div>
        
                    </div>
                </div>
                
                
                
            </div>
        </div>
    </div>

   

    <div className="container-xxl about my-5">
        <div className="container">
            <div className="row g-0">
                <div className="col-lg-6">
                    <div className="h-100 d-flex align-items-center justify-content-center" style={{minHeight: "300px"}}>
                        <button type="button" className="btn-play" data-bs-toggle="modal"
                            data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-bs-target="#videoModal">
                            <span></span>
                        </button>
                    </div>
                </div>
                <div className="col-lg-6 pt-lg-5 wow fadeIn" data-wow-delay="0.5s">
                    <div className="bg-white rounded-top p-5 mt-lg-5">
                        <p className="fs-5 fw-medium text-primary">About Us</p>
                        <h1 className="display-6 mb-4">Why are we more suitable for your business</h1>
                        <p className="mb-4">Our agility and calibre have really extended in these years because we have solved various client’s hiring challenges right from sourcing to on-boarding including hrms solutions necessary for employee engagement and development</p>
                        <div className="row g-5 pt-2 mb-5">
                            <div className="col-sm-6">
                                <img className="img-fluid mb-4" src="img/icon/icon-5.png" alt="" />
                                <h5 className="mb-3">85% Manpower supply success</h5>
                                {/* <span>Clita erat ipsum et lorem et sit sed stet lorem</span> */}
                            </div>
                            <div className="col-sm-6">
                                <img className="img-fluid mb-4" src="img/icon/icon-2.png" alt="" />
                                <h5 className="mb-3">95% Precise profiles matches for clients</h5>
                                {/* <span>Clita erat ipsum et lorem et sit sed stet lorem</span> */}
                            </div>
                        </div>
                        <a className="btn btn-primary rounded-pill py-3 px-5" href="">Explore More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div className="modal modal-video fade" id="videoModal" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content rounded-0">
                <div className="modal-header">
                    <h3 className="modal-title" id="exampleModalLabel">Youtube Video</h3>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="ratio ratio-16x9">
                        <iframe className="embed-responsive-item" src="" id="video" allowfullscreen
                            allowscriptaccess="always" allow="autoplay"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
 
    <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
                <p className="fs-5 fw-medium text-primary">TESTIMONIALS</p>
                <h1 className="display-5 mb-2">What Clients Say About Us?</h1>
            </div>
            <div className="row g-4">
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-item position-relative h-100">
                        <div className="service-text rounded p-5"  style={{height:"90%"}}>
                            <div className="btn-square bg-light rounded-circle mx-auto mb-4"
                                style={{width: "64px", height: "64px"}}>
                        <img decoding="async" src="https://rstheme.com/products/wordpress/bcom-rtl/wp-content/uploads/2023/02/quote-5.svg" className="normal__img" alt="image"/>


                            </div>
                                <p className="mb-0">We have been in touch with Pearlcon since 2 years, their understanding of the business needs and organization culture enable them to provide us best applicants from best of industries. I better recommend them as professional, diligent and comparably one of the flexible HR consultant in chennai</p>
                                <div style={{marginTop:"50px"}}> 

                                    <h5>Hanumant</h5>
                                    <p>S.r Exec. HR , Cleomesoft Technologies pvt ltd</p>
                                </div>

                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="service-item position-relative h-100">
                        <div className="service-text rounded p-5" style={{height:"90%"}}>
                            <div className="btn-square bg-light rounded-circle mx-auto mb-4"
                                style={{width: "64px", height: "64px"}}>
                            <img decoding="async" src="https://rstheme.com/products/wordpress/bcom-rtl/wp-content/uploads/2023/02/quote-5.svg" className="normal__img" alt="image"/>

                            </div>
                                <p className="mb-0">The services are prompt, Lead recruiters have good knowledge about the JD, good behavioral skills and approach towards the Mandates.</p>
                            <div style={{marginTop:"170px"}}> 
                                <h5>Josheph Jhon</h5>
                                <p>HR Manager - Baxture Technologies Pvt ltd</p>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="service-item position-relative h-100">
                        <div className="service-text rounded p-5"  style={{height:"90%"}}>
                            <div className="btn-square bg-light rounded-circle mx-auto mb-4"
                                style={{width: "64px", height: "64px"}}>
                                <img decoding="async" src="https://rstheme.com/products/wordpress/bcom-rtl/wp-content/uploads/2023/02/quote-5.svg" className="normal__img" alt="image"/>

                            </div>
                                <p className="mb-0">Vasanth have been helpful in managing my manpower at starting stages of our Company . He &amp; his knowledgeable coordinated the entire process. Did a wonderful job and kept me updated periodically. They dealt with affordable payment processes  and follow up was really good. Keep going with colors.</p>
                            <div style={{marginTop:"50px"}}> 
    
                                <h5>Dhilip Haridass</h5>
                                <p>Senior Director - Arinosoft Info Solutions</p>
                            </div>
                        </div>
                       
                    </div>
                </div>
                
            </div>
        </div>
    </div>

    <div className="container-xxl pt-5">
        <div className="container">
            {/* <div className="text-center text-md-start pb-5 pb-md-0 wow fadeInUp" data-wow-delay="0.1s"
                style={{maxWidth: "500px"}}>
                <p className="fs-5 fw-medium text-primary">Our Projects</p>
                <h1 className="display-5 mb-5">We've Done Lot's of Awesome Projects</h1>
            </div> */}
            <div className="owl-carousel project-carousel wow fadeInUp" data-wow-delay="0.1s">
                <div className="project-item mb-5">
                    <div className="position-relative">
                        <img className="img-fluid" src="img/project-1.jpg" alt="" />
                        <div className="project-overlay">
                            <a className="btn btn-lg-square btn-light rounded-circle m-1" href="img/project-1.jpg"
                                data-lightbox="project"><i className="fa fa-eye"></i></a>
                            <a className="btn btn-lg-square btn-light rounded-circle m-1" href=""><i
                                    className="fa fa-link"></i></a>
                        </div>
                    </div>
                    <div className="p-4">
                        <a className="d-block h5" href="">Data Analytics & Insights</a>
                        <span>Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem</span>
                    </div>
                </div>
                <div className="project-item mb-5">
                    <div className="position-relative">
                        <img className="img-fluid" src="img/project-2.jpg" alt="" />
                        <div className="project-overlay">
                            <a className="btn btn-lg-square btn-light rounded-circle m-1" href="img/project-2.jpg"
                                data-lightbox="project"><i className="fa fa-eye"></i></a>
                            <a className="btn btn-lg-square btn-light rounded-circle m-1" href=""><i
                                    className="fa fa-link"></i></a>
                        </div>
                    </div>
                    <div className="p-4">
                        <a className="d-block h5" href="">Marketing Content Strategy</a>
                        <span>Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem</span>
                    </div>
                </div>
                <div className="project-item mb-5">
                    <div className="position-relative">
                        <img className="img-fluid" src="img/project-3.jpg" alt="" />
                        <div className="project-overlay">
                            <a className="btn btn-lg-square btn-light rounded-circle m-1" href="img/project-3.jpg"
                                data-lightbox="project"><i className="fa fa-eye"></i></a>
                            <a className="btn btn-lg-square btn-light rounded-circle m-1" href=""><i
                                    className="fa fa-link"></i></a>
                        </div>
                    </div>
                    <div className="p-4">
                        <a className="d-block h5" href="">Business Target Market</a>
                        <span>Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem</span>
                    </div>
                </div>
                <div className="project-item mb-5">
                    <div className="position-relative">
                        <img className="img-fluid" src="img/project-4.jpg" alt="" />
                        <div className="project-overlay">
                            <a className="btn btn-lg-square btn-light rounded-circle m-1" href="img/project-4.jpg"
                                data-lightbox="project"><i className="fa fa-eye"></i></a>
                            <a className="btn btn-lg-square btn-light rounded-circle m-1" href=""><i
                                    className="fa fa-link"></i></a>
                        </div>
                    </div>
                    <div className="p-4">
                        <a className="d-block h5" href="">Social Marketing Strategy</a>
                        <span>Erat ipsum justo amet duo et elitr dolor, est duo duo eos lorem</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
   
    <div className="container-xxl py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <p className="fs-5 fw-medium text-primary">Get A Quote</p>
                    <h1 className="display-5 mb-4">Need Our Expert Help? We're Here!</h1>
                    <p>Experienced professionals are waiting to make a difference in your organisation. Get in touch with us to find your next great hire.</p>
                    <p>We proactively keep in touch clients and candidates. So explore our unique and extensive capabilities now.</p>
                    {/* <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et
                        eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo</p> */}
                    {/* <a className="d-inline-flex align-items-center rounded overflow-hidden border border-primary" href="">
                        <span className="btn-lg-square bg-primary" style={{width: "55px" ,height: "55px"}}>
                            <i className="fa fa-phone-alt text-white"></i>
                        </span>
                        <span className="fs-5 fw-medium mx-4">+012 345 6789</span>
                    </a> */}
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <h2 className="mb-4">Get A Free Quote</h2>
                    <div className="row g-3">
                        <div className="col-sm-6">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                <label for="name">Your Name</label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-floating">
                                <input type="email" className="form-control" id="mail" placeholder="Your Email" />
                                <label for="mail">Your Email</label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-floating">
                                <input type="text" className="form-control" id="mobile" placeholder="Your Mobile" />
                                <label for="mobile">Your Mobile</label>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="form-floating">
                                <select className="form-select" id="service">
                                    <option selected>IT</option>
                                    <option value="">Non-IT</option>
                                    
                                </select>
                                <label for="service">Select a Industry</label>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-floating">
                                <textarea className="form-control" placeholder="Leave a message here" id="message"
                                    style={{height: "130px"}}></textarea>
                                <label for="message">Message</label>
                            </div>
                        </div>
                        <div className="col-12 text-center">
                            <button className="btn btn-primary w-100 py-3" type="submit">Submit Now</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container-xxl py-5">
        <div className="container-fluid">
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
                <p className="fs-5 fw-medium text-primary">BLOGS</p>
                <h1 className="display-5 mb-2">Our Blogs</h1>
            </div>

            <div className="row">
            <div className="col-lg-4 wow fadeInUp d-flex align-items-center justify-content-center" data-wow-delay="0.1s">

                <div className="card">
                  <div className="card__header">
                    <img src="img/blog1.jpg" alt="card__image" className="card__image" width="600" height="200" />
                  </div>
                  <div className="card__body" style={{height:"200px"}}>
                    {/* <span className="tag tag-blue">Technology</span> */}
                    <h4>Some of the better ways for Placement / Job</h4>
                    <p>For every student or candidate, placement season is the most...</p>
                  </div>
                  <div className="card__footer">
                    <div className="user">
                    <a href="https://www.pearlcons.com/some-of-the-better-ways-for-placement-interview-preparation-for-candidates/"><button type="button"  class="btn btn-primary blogbut">Read More</button></a>

                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 wow fadeInUp d-flex align-items-center justify-content-center" data-wow-delay="0.5s">

                <div className="card">
                  <div className="card__header">
                    <img src="img/blog2.jpg" alt="card__image" className="card__image" width="600" height="200"  />
                  </div>
                  <div className="card__body" style={{height:"200px"}}>
                    {/* <span className="tag tag-brown">Food</span> */}
                    <h4>How Can Corporate Restructuring Be Achieved Better and What</h4>
                    <p>At some stage in a business lifecycle, many businesses undergo...</p>
                  </div>
                  <div className="card__footer">
                    <div className="user">
                        <a href="https://www.pearlcons.com/how-can-corporate-restructuring-be-achieved-better-and-what-does-it-mean/"><button type="button" class="btn btn-primary blogbut">Read More</button></a>

                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 wow fadeInUp d-flex align-items-center justify-content-center" data-wow-delay="0.5s">

                <div className="card">
                  <div className="card__header">
                    <img src="img/blog3.jpg" alt="card__image" className="card__image" width="600" height="200" />
                  </div>
                  <div className="card__body" style={{height:"200px"}}>
                    {/* <span className="tag tag-red">Automobile</span> */}
                    <h4>The Expansion, Patterns, and Obstacles of India’s IT Sector</h4>
                    <p>Despite facing significant global challenges, India demonstrated remarkable economic expansion...</p>
                  </div>
                  <div className="card__footer">
                    <div className="user">
                      <a href="https://www.pearlcons.com/the-expansion-patterns-and-obstacles-of-indias-it-sector-in-2024/"><button type="button" class="btn btn-primary blogbut">Read More</button></a>



                      
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
    </div>

        {/* <div className="container-fluid">
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                <p className="fs-5 fw-medium text-primary">Blogs</p>
                <h1 className="mb-5">Our Top Blogs & Updates  </h1>
            </div>
            <div className="row g-4">
               
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                    <div className="team-item rounded overflow-hidden pb-4">
                        <img className="img-fluid mb-4" src="img/team-2.jpg" alt="" />  
                        <h5>Adam Crew</h5>
                        <span className="text-primary">Co Founder</span>
                        <ul className="team-social">
                            <li><a className="btn btn-square" href=""><i className="fab fa-facebook-f"></i></a></li>
                            <li><a className="btn btn-square" href=""><i className="fab fa-twitter"></i></a></li>
                            <li><a className="btn btn-square" href=""><i className="fab fa-instagram"></i></a></li>
                            <li><a className="btn btn-square" href=""><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="team-item rounded overflow-hidden pb-4">
                        <img className="img-fluid mb-4" src="img/team-3.jpg" alt="" />
                        <h5>Boris Johnson</h5>
                        <span className="text-primary">Executive Manager</span>
                        <ul className="team-social">
                            <li><a className="btn btn-square" href=""><i className="fab fa-facebook-f"></i></a></li>
                            <li><a className="btn btn-square" href=""><i className="fab fa-twitter"></i></a></li>
                            <li><a className="btn btn-square" href=""><i className="fab fa-instagram"></i></a></li>
                            <li><a className="btn btn-square" href=""><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="team-item rounded overflow-hidden pb-4">
                        <img className="img-fluid mb-4" src="img/team-4.jpg" alt="" />
                        <h5>Robert Jordan</h5>
                        <span className="text-primary">Digital Marketer</span>
                        <ul className="team-social">
                            <li><a className="btn btn-square" href=""><i className="fab fa-facebook-f"></i></a></li>
                            <li><a className="btn btn-square" href=""><i className="fab fa-twitter"></i></a></li>
                            <li><a className="btn btn-square" href=""><i className="fab fa-instagram"></i></a></li>
                            <li><a className="btn btn-square" href=""><i className="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div> */}
    </div>
 
   
    </>

    )}
export default Home;