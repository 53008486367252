import React, { useState,useRef } from 'react';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './joke.css'; 
import './bootstrap.min.css'; 



function Searchjobs()
{

    useEffect(()=>{
        document.title = "Search Jobs in India | Placement agency in India |Pearlcon";
        document.querySelector('meta[name="description"]').setAttribute('content', "Explore various in-demand jobs in India on your expected locations with easy applications on our site. Ready to join our team & land on your dream job?");
        if (contact1Ref.current) {
            contact1Ref.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);

  

    const { id } = useParams(); 
    const [rr, setId] = useState("");
    const [le, setDatas] = useState([]);

    useEffect(() => {
        setId(id);
    }, [id]);

    useEffect(() => {
        setFormData(prevState => ({
            ...prevState,
            jobtitle: rr
        }));
    }, [rr]);

    const contact1Ref = useRef(null);

    const handleApplyClick = (roleData) => {
        setId(roleData);
        handleScrollToContact(contact1Ref);
    };

    // Function to scroll to contact
    const handleScrollToContact = (contactRef) => {
        if (contactRef.current) {
            contactRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

      const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        jobtitle: rr,
        noticeperiod: '',
        experience: '',
        resume: null // Initialize resume field as null
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        if (type === 'file') {
            // For file input, handle differently
            setFormData({
                ...formData,
                [name]: e.target.files[0] // Store the file object directly
            });
        } else {
            // For other input types
            setFormData({
                ...formData,
                [name]: value
            });
        }
        // Clear validation error if field is edited
        setErrors({
            ...errors,
            [name]: ''
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Validation
        const newErrors = {};
        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }
        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        }
        if (!formData.phone.trim()) {
            newErrors.phone = 'Phone is required';
        }
        if (!formData.jobtitle.trim()) {
            newErrors.jobtitle = 'Job Title is required';
        }
        if (!formData.noticeperiod.trim()) {
            newErrors.noticeperiod = 'Notice Period is required';
        }
        if (!formData.experience.trim()) {
            newErrors.experience = 'Experience is required';
        }
        if (!formData.resume) {
            newErrors.resume = 'Resume is required';
        }
    
        setErrors(newErrors);
    
        if (Object.keys(newErrors).length > 0) {
            return;
        }
    
        // If validation passes, create FormData object to handle file data
        try {
            const formDataToSend = new FormData();
            formDataToSend.append('name', formData.name);
            formDataToSend.append('email', formData.email);
            formDataToSend.append('phone', formData.phone);
            formDataToSend.append('jobtitle', formData.jobtitle);
            formDataToSend.append('noticeperiod', formData.noticeperiod);
            formDataToSend.append('experience', formData.experience);
            formDataToSend.append('resume', formData.resume);
    
            const response = await fetch('https://www.pearlcons.com/hrms/pearlchrms/api/jobrequirementsstore', {
                method: 'POST',
                mode: 'cors',
                body: formDataToSend
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
    
            const data = await response.json();
            console.log('Data sent successfully:', data);
            document.getElementById('formContainer').innerHTML += '<div class="thank-you-message">Thank you for applying for this job!</div>';


            setTimeout(() => {
                document.querySelector('.thank-you-message').style.display = 'none';
            }, 5000);
            // Clear form data if needed
            setFormData({
                name: '',
                email: '',
                phone: '',
                jobtitle: '',
                noticeperiod: '',
                experience: '',
                resume: ''
            });
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            alert('There was a problem submitting the form. Please try again later.');
        }
    };
    

    return(
        <>

        <div className="py-5" style={{backgroundColor:"#2054ae"}}>
        <div className="container-fluid">
            
            <div className="row g-4">
               
                
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div>
                       <h1 style={{color:"#ffffff",fontSize:"40px"}}>Forge your career path with us.</h1>
                       <p style={{color:"#ffffff",fontSize:"20px"}}>Join hands with Pearlcon and let us explore enormous jobs together.</p>
                    </div>
                     
                </div>
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                <div style={{float:"inline-end"}}>
                        <img src={`${process.env.PUBLIC_URL}/img/ss.png`} alt="My Image" />

                       
                    </div>
                </div>
            </div>
        </div>
        </div>




    <div className="container-xxl py-5">
        <div className="container">
            <div className="mx-auto wow fadeInUp" data-wow-delay="0.1s">
                {/* <p className="fs-5 fw-medium text-primary">Contact Us</p> */}
                <h1 className="mb-5 text-center">Search Jobs Flexible for You</h1>
                <p className="fs-5 fw-medium text-center">We eliminate all uncertainty in locating your suitable job for the maximum we can.</p>
            </div>
            <div className="row g-5">
                <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
                <div className="card-body p-0">
                <div className="table-responsive table-scroll" data-mdb-perfect-scrollbar="true">
                  <table className="table table-striped mb-0">
                    <thead style={{backgroundColor: "#002d72"}}>
                      <tr>
                        <th scope="col" style={{color:"#ffffff"}}>Role</th>
                        <th scope="col" style={{color:"#ffffff"}}>Skills</th>
                        <th scope="col" style={{color:"#ffffff"}}>Experience</th>
                        <th scope="col" style={{color:"#ffffff"}}>Location</th>
                        <th scope="col" style={{color:"#ffffff"}}>Positions</th>
                        <th scope="col" style={{color:"#ffffff"}}>Actions</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>IBM MDM Sr.Developer</td>
                        <td>Expert Java, JEE Development background, WebSphere Server, REST Web Services</td>
                        <td>7 to 12</td>
                        <td>PAN India</td>
                        <td>1</td>

                        <td><button onClick={() => handleApplyClick("IBM MDM Sr.Developer")} className="btn btn-primary">Apply</button></td>
                      </tr>
                      <tr>
                        <td>Angular</td>
                        <td>Angular & Node</td>
                        <td>6 months to 3</td>
                        <td>Chennai</td>
                        <td>1</td>
                        <td><button onClick={() => handleApplyClick("Angular")} className="btn btn-primary">Apply</button></td>



                      </tr>
                      <tr>
                        <td>ACM and Entrust PKI Certificate Management support</td>
                        <td>Excellent English written and verbal communication skills, i.e., active listening, ability to clearly summarize an issue , both in written and verbally</td>
                        <td>2 to 6</td>
                        <td></td>
                        <td>1</td>
                        <td><button onClick={() => handleApplyClick("ACM and Entrust PKI Certificate Management support")} className="btn btn-primary">Apply</button></td>




                      </tr>
                      <tr>
                        <td>Al/ML Data Scientist</td>
                        <td>Overall, 2+ years of direct experience working with AI/ML based implementations Strong programming language skills in Python, R, and Tableau to be able to synthesize large unstructured data</td>
                        <td>2 to 5</td>
                        <td>Chennai</td>
                        <td>1</td>
                        <td><button onClick={() => handleApplyClick("Al/ML Data Scientist")} className="btn btn-primary">Apply</button></td>



                      </tr>
                      <tr>
                        <td>Associate Manager - Strategic Analysis</td>
                        <td>Statistical tool - SPSS,WinCross,SAS,Python & Data Analysis</td>
                        <td>5+</td>
                        <td>Chennai</td>
                        <td>2</td>
                        <td><button onClick={() => handleApplyClick("Associate Manager - Strategic Analysis")} className="btn btn-primary">Apply</button></td>


                      </tr>
                      <tr>
                        <td>AUTOSAR</td>
                        <td>Hands-on experience in Autosar methodologies and workflows</td>
                        <td>4+</td>
                        <td>Banglore</td>
                        <td>40+</td>
                        <td><button onClick={() => handleApplyClick("Hands-on experience in Autosar methodologies and workflows")} className="btn btn-primary">Apply</button></td>


                      </tr>
                      <tr>
                        <td>AWS Devops</td>
                        <td>Java,AWS with Devops</td>
                        <td>5+</td>
                        <td>Chennai</td>
                        <td>1</td>
                        <td><button onClick={() => handleApplyClick("AWS Devops")} className="btn btn-primary">Apply</button></td>


                      </tr>
                      <tr>
                        <td>Azure Developer with .Net</td>
                        <td>Responsible for .NET development using Domain</td>
                        <td>3 to 6</td>
                        <td>Bangalore</td>
                        <td>1</td>
                        <td><button onClick={() => handleApplyClick("Azure Developer with .Net")} className="btn btn-primary">Apply</button></td>


                      </tr>
                      <tr>
                        <td>Tech Lead - Java</td>
                        <td>Java, Spring, Sql</td>
                        <td>5+ Years</td>
                        <td>Chennai - WFO</td>
                        <td>1</td>
                        <td><button onClick={() => handleApplyClick("Tech Lead - Java")} className="btn btn-primary">Apply</button></td>


                      </tr>
                      <tr>
                        <td>.Net Full stack developer</td>
                        <td>.Net, .Net Core,MVC,Entity framework</td>
                        <td>4 to 8</td>
                        <td>Bangalore</td>
                        <td>1</td>
                        <td><button onClick={() => handleApplyClick(".Net Full stack developer")} className="btn btn-primary">Apply</button></td>


                      </tr>
                      <tr>
                        <td>IBM MDM(SE) Developer</td>
                        <td>SOAP</td>
                        <td>3 to 5 Years</td>
                        <td>Remote</td>
                        <td>1</td>
                        <td><button onClick={() => handleApplyClick("IBM MDM(SE) Developer")} className="btn btn-primary">Apply</button></td>


                      </tr>
                      <tr>
                        <td>SAP FICO/TRM</td>
                        <td>SAP CO Sub Modules GL, GR, AP, FA, and banking</td>
                        <td>5 to 8 Years</td>
                        <td>Chennai</td>
                        <td>1</td>
                        <td><button onClick={() => handleApplyClick("SAP FICO/TRM")} className="btn btn-primary">Apply</button></td>


                      </tr>
                      <tr>
                        <td>Senior QA Engineer - Python Automation</td>
                        <td>Storage stack, file system and testing tool like fio, fsct, vdbench, dd, SpecFs and experience in programming Go, Python, Bash</td>
                        <td>5+ Years</td>
                        <td>Pune</td>
                        <td>1</td>
                        <td><button onClick={() => handleApplyClick("Senior QA Engineer - Python Automation")} className="btn btn-primary">Apply</button></td>


                      </tr>
                      
                    </tbody>

         
                  </table>


                </div>
              </div>
                </div>
            </div>
        </div>
    </div>




<div className="py-5" ref={contact1Ref} style={{ margin: '20px', padding: '10px', border: '1px solid black', backgroundColor:"#5c4084", borderRadius: '10px' }}>
    <div className="container-fluid">
        <h1 className="text-center text-white" id="apply">Apply Now</h1>
        <h4 className="text-center" style={{color:"#ffffff"}}>Send us your application, our team will respond to you quickly.</h4>

        <div className="row g-4">
            <div style={{ marginInline:"auto" }} id="se" className="col-lg-12 col-md-6 wow fadeInUp card text-center align-items-center d-flex justify-content-center flex-nowrap w-50" data-wow-delay="0.5s">
                <div className="card-body mb-2" >
                    <form onSubmit={handleSubmit} className="needs-validation" noValidate>
                        <div className="form-group mb-3">
                            <input type="text" name="name" className="form-control input-field" placeholder="Your Name *" value={formData.name} onChange={handleChange} required />
                            <div className="invalid-feedback">Please enter your name.</div>
                            {errors.name && <span className="text-danger">{errors.name}</span>}
                        </div>
                        <div className="form-group mb-3">
                            <input type="email" name="email" className="form-control input-field" placeholder="Your Email *" value={formData.email} onChange={handleChange} required />
                            <div className="invalid-feedback">Please enter a valid email address.</div>
                            {errors.email && <span className="text-danger">{errors.email}</span>}
                        </div>
                        <div className="form-group mb-3">
                            <input type="number" name="phone" className="form-control input-field" placeholder="Your Phone Number *" value={formData.phone} onChange={handleChange} required />
                            <div className="invalid-feedback">Please enter your phone number.</div>
                            {errors.phone && <span className="text-danger">{errors.phone}</span>}
                        </div>
                        <div className="form-group mb-3">
                            <input type="text" name="jobtitle" className="form-control input-field" placeholder="Enter Job Title *" readOnly value={formData.jobtitle} onChange={handleChange} required />
                            <div className="invalid-feedback">Please enter your job title.</div>
                            {errors.jobtitle && <span className="text-danger">{errors.jobtitle}</span>}
                        </div>
                        <div className="form-group mb-3">
                            <input type="text" name="noticeperiod" className="form-control input-field" placeholder="Notice Period *" value={formData.noticeperiod} onChange={handleChange} required />
                            <div className="invalid-feedback">Please enter your notice period.</div>
                            {errors.noticeperiod && <span className="text-danger">{errors.noticeperiod}</span>}
                        </div>
                        <div className="form-group mb-3">
                            <input type="text" name="experience" className="form-control input-field" placeholder="Years of Experience *" value={formData.experience} onChange={handleChange} required />
                            <div className="invalid-feedback">Please enter your years of experience.</div>
                            {errors.experience && <span className="text-danger">{errors.experience}</span>}
                        </div>
                        <div className="form-group mb-3">
                            <input type="file" name="resume" className="form-control input-field" onChange={handleChange} required />
                            <div className="invalid-feedback">Please upload your resume.</div>
                            {errors.resume && <span className="text-danger">{errors.resume}</span>}
                        </div>
                        <div className="form-group mb-3">
                            <button type="submit" className="btn btn-primary submit-btn">Submit</button>
                        </div>
                        <div id="formContainer">
                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>


        </>
    )
}
export default Searchjobs;