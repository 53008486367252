import React from "react";
import './blog.css'; 
import { useEffect } from "react";
import './bootstrap.min.css'; 
import './joke.css'; 

function Hrsolution()
{
    useEffect(()=>{
        document.title = "Leveraged HR Consulting & Job placements in India | Pearlcon"
    })
    return(
        <>

        <div className="py-5" style={{backgroundColor:"#2054ae"}}>
            <div className="container-fluid">
            <div className="row g-4">
               
                
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div style={{paddingTop:"40px"}}>
                       <h1 style={{color:"#ffffff",fontSize:"40px"}}>Investing our experience in HR transformation & placements</h1>
                       <p style={{color:"#ffffff",fontSize:"20px"}}>We focus on quality driven HR consulting services for every sort of MSMEs functioning in India & overseas.  Whether you need  permanent, transitory, or contract staffing, we give streamlined HR transformational solutions with new enlisting hones required for each perspective of human resources administration.</p>

                    </div>
                </div>
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div style={{float:"inline-end"}}>
                        <img className="img-fluid" src="img/challenge.png" alt="" />
                       
                    </div>
                </div>
            </div>
        </div>
        </div>

        
        <div className="py-5" style={{backgroundColor:"#f4f9fe"}}>
            <div className="container-fluid">
                <h2 className="text-center mb-5">We empower you with our strategic HR consulting initiatives With Smart Team</h2>

            <div className="row g-4">
               
                
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="shadow-lg text-center p-3 mb-5 bg-body rounded effect-1" style={{height:"370px"}}>
                        <div style={{display:"inline-block"}}>
                            <img className="img-fluid text-center" src="img/it-solution.png" style={{height:"80px"}}  alt="" />
                        </div>
                        <div className="my-3">
                            <h4>Employee Lifecycle Management</h4>
                        </div>
                        <div>
                            <p>The hiring process to separation is all included in the HR Life Cycle, and HR is essential at every turn. Encourage change management within your company. HR can store, manage, and track all employee information, including personal information, job titles, pay, and tenure, with the use of effective employee data management.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="shadow-lg p-3 mb-5 bg-body text-center rounded effect-1" style={{height:"370px"}}>
                        <div style={{display:"inline-block"}}>
                            <img className="img-fluid" src="img/aa2.png" style={{height:"80px"}}  alt="" />
                        </div>
                        <div className="my-3">
                            <h4>HR Transformational Services</h4>
                        </div>
                        <div>
                            <p>Custom HR policies and transformation programs are developed by Pearlcon. These programs include thorough employee handbooks and training courses that enlighten and educate staff members on company benefits and policies at every stage of their employment. Create metrics for HR performance and market your human capital.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="shadow-lg p-3 mb-5 bg-body rounded text-center effect-1" style={{height:"370px"}}>
                        <div style={{display:"inline-block"}}>
                            <img className="img-fluid" src="img/aa3.png" style={{height:"80px"}}  alt="" />
                        </div>
                        <div className="my-3">
                            <h4>Organization Structuring</h4>
                        </div>
                        <div>
                            <p>Use our strategic framework to match people management techniques to long-term business goals while taking changing labour demands and resource constraints into account. Our assistance is in creating an organizational structure that maximises assets and boosts efficiency, resulting in sustained prosperity.</p>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="row g-4"> 

                
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="shadow-lg p-3 mb-5 bg-body text-center rounded effect-1" style={{height:"370px"}}>
                        <div style={{display:"inline-block"}}>
                            <img className="img-fluid" src="img/front-development.png" style={{height:"80px"}}  alt="" />
                        </div>
                        <div className="my-3">
                            <h4>Sourcing & Screening</h4>
                        </div>
                        <div>
                            <p>There are many stages in recruitment process, we give importance from the start itself right from finding the relevant profiles to validating them based on the client requirements. From form submission to status tracking and process completion, your candidates will have a smooth screening experience thanks to our frictionless procedure</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="shadow-lg p-3 mb-5 bg-body text-center rounded effect-1" style={{height:"370px"}}>
                        <div style={{display:"inline-block"}}>
                            <img className="img-fluid" src="img/shield.png" style={{height:"80px"}}  alt="" />
                        </div>
                        <div className="my-3">
                            <h4>Candidate Background Checks</h4>
                        </div>
                        <div>
                            <p>Facilitate large-scale recruiting by conducting background checks on several applicants at once. Reduce expenses, free up time, and effectively manage resources. Handle a lot of verifications, monitor developments, and make confident hiring decisions.</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="shadow-lg p-3 mb-5 text-center bg-body rounded effect-1" style={{height:"370px"}}>
                        <div style={{display:"inline-block"}}>
                            <img className="img-fluid" src="img/technology.png" style={{height:"80px"}}  alt="" />
                        </div>
                        <div className="my-3">
                            <h4>Working On Client Feedbacks</h4>
                        </div>
                        <div>
                            <p>By requesting feedback from our clients, we show them that you value their opinions. They get more invested in our business because we let them help to shape our business. we thereby build better relationships with customers by listening to what they have to say about our HR consulting services in india.</p>
                        </div>
                    </div>
                </div>


              
            </div>
        </div>
        </div>


        <div className="py-5">
            <div className="container-fluid" >

            <div className="row g-4">
                

                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                    <div className="bord">
                        <h1>We partnered with 30+ companies</h1>
                    </div>
                    <div style={{marginTop:"20px"}}> 
                        <p>Our dedicated HR consulting team in chennai & pune offers you everyday assistance besides affordability with developing your HR strategies, job placements across india, and long-term maintenance in addition to integration of HRMS solutions. </p>
                    </div>
                    <div className="text-end" style={{marginTop:"20px"}}>
                        <button className="btn btn-primary round">View our Brochure</button>
                    </div>
                    <div className="swing d-flex text-center align-items-center justify-content-center">
                        <img className="img-fluid" src="img/Group-478.png" style={{height:"400px"}} alt="" />
                    </div>
                    
                </div>

                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="row row-cols-3">
                        <div className="col border align-items-center text-center" style={{borderTopLeftRadius: "10px",alignContent:"space-evenly",height:"180px"}}><img className="img-fluid immm" src="img/CAMS.png" style={{paddingTop:"30px",paddingLeft:"20px",paddingRight:"20px",paddingBottom:"30px"}}  alt="" /></div>
                        <div className="col border" style={{alignContent:"space-evenly",height:"180px"}}><img className="img-fluid immm" src="img/gavs.png" style={{paddingTop:"30px",paddingLeft:"20px",paddingRight:"20px",paddingBottom:"30px"}}  alt="" /></div>
                        <div className="col border" style={{borderTopRightRadius: "10px",alignContent:"space-evenly",height:"180px"}}><img className="img-fluid immm" src="img/sysveda.jpg" style={{paddingTop:"30px",paddingLeft:"20px",paddingRight:"20px",paddingBottom:"30px"}}  alt="" /></div>
                        <div className="col border" style={{alignContent:"space-evenly",height:"180px"}}><img className="img-fluid immm" src="img/aram-1.png" style={{paddingTop:"30px",paddingLeft:"20px",paddingRight:"20px",paddingBottom:"30px"}} alt="" /></div>
                        <div className="col border" style={{alignContent:"space-evenly",height:"180px"}}><img className="img-fluid immm" src="img/MPS-Company.png" style={{paddingTop:"30px",paddingLeft:"20px",paddingRight:"20px",paddingBottom:"30px"}}  alt="" /></div>
                        <div className="col border" style={{alignContent:"space-evenly",height:"180px"}} ><img className="img-fluid immm" src="img/srm.png" style={{paddingTop:"30px",paddingLeft:"20px",paddingRight:"20px",paddingBottom:"30px"}}  alt="" /></div>
                        <div className="col border" style={{alignContent:"space-evenly",height:"180px"}} ><img className="img-fluid immm" src="img/won.jpg" style={{paddingTop:"30px",paddingLeft:"20px",paddingRight:"20px",paddingBottom:"30px"}}  alt="" /></div>
                        <div className="col border" style={{alignContent:"space-evenly",height:"180px"}} ><img className="img-fluid immm" src="img/ba.png" style={{paddingTop:"30px",paddingLeft:"20px",paddingRight:"20px",paddingBottom:"30px"}}  alt="" /></div>
                        <div className="col border" style={{alignContent:"space-evenly",height:"180px"}}><img className="img-fluid immm" src="img/ka.jpg" style={{paddingTop:"30px",paddingLeft:"20px",paddingRight:"20px",paddingBottom:"30px"}}  alt="" /></div>
                        <div className="col border" style={{borderBottomLeftRadius: "10px",alignContent:"space-evenly",height:"180px"}}><img className="img-fluid immm" src="img/gi.png" style={{paddingTop:"30px",paddingLeft:"20px",paddingRight:"20px",paddingBottom:"30px"}}  alt="" /></div>
                        <div className="col border" style={{alignContent:"space-evenly",height:"180px"}}><img className="img-fluid immm" src="img/sree.jpg" style={{paddingTop:"30px",paddingLeft:"20px",paddingRight:"20px",paddingBottom:"30px"}}  alt="" /></div>
                        <div className="col border" style={{borderBottomRightRadius: "10px",alignContent:"space-evenly",height:"180px"}}><img className="img-fluid immm" src="img/svcon.png" style={{paddingTop:"30px",paddingLeft:"20px",paddingRight:"20px",paddingBottom:"30px"}}  alt="" /></div>


                    </div>

                </div>

               
            </div>
        </div>
        </div>

        <div className="py-5">
            <div className="container">
                <h2 className="mb-5">SOME FREQUENTLY ASKED QUESTIONS</h2>

            <div className="row g-4">
               
                
                <div className="col-lg-12 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div style={{backgroundColor:"#f7f7f7",padding:"10px"}}>
                        <h2><i className="fa fa-check"></i> What are the components of HR Consulting solutions?</h2>
                    </div>
                    <div style={{marginTop:"20px"}}>
                        <p>Giving guidance on significant issues including hiring, employee lifecycle, staff management, overall rewards, and more is a part of HR consulting solutions.  Their main goal is to assist in creating the long-term internal procedures and policies that are suitable for your company at this stage of its growth.</p>
                    </div>
                    <div style={{backgroundColor:"#f7f7f7",padding:"10px"}}>
                        <h2><i className="fa fa-check"></i> What are the key business considerations for HR consultancy?</h2>
                    </div>
                    <div style={{marginTop:"20px"}}>
                        <p> A  team of consulting firm considers the target market, legal structure, management team,  financial, insurance and administrative requirements for functioning in the market.</p>
                    </div>
                    <div style={{marginTop:"20px"}}>
                        <p>They need to find lots of opportunities to acquire the clients in smartest ways possible among the exisiting competitors as we do. We have the well-planned , strategized and structured team to manage various challenges right from sourcing to on-boarding the candidates. </p>
                    </div>
                    <div style={{backgroundColor:"#f7f7f7",padding:"10px"}}>
                        <h2><i className="fa fa-check"></i> How do we handle the workflow and process here?</h2>
                    </div>
                    <div style={{marginTop:"20px"}}>
                        <p>Generally, we will adhere to a procedure for HR consulting process that consists of four definite phases: recommendation, analysis, implementation, and discovery.  </p>
                    </div>
                    <div style={{marginTop:"20px"}}>
                        <p>Our objective is to implement believable changes that will benefit both new and existing staff members as well as the general well-being of your company. We support employee engagement, strategic HR initiatives, and monthly HR support so that any adjustments to your HR strategy that may require some assistance in the long-run.</p>
                    </div>
                   
                </div>
                </div>
            </div>
        </div>
  
        </>
    )
}
export default Hrsolution;