import React, { useState, useEffect } from 'react';
import './bootstrap.min.css'; 
import './joke.css'; 
import { NavLink } from 'react-router-dom';
function Navbar(){
    const [id, setIds] = useState(1);

    const handleClick = () => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };
        return (
            <>
            
        <div className="container-fluid bg-white sticky-top">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg bg-white navbar-light p-lg-0" >
                    <a href="index.html" className="navbar-brand d-lg-none">
                        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/pearl.png`} style={{height:"57px"}} alt="Icon" />
                    </a>
                    <button type="button" className="navbar-toggler me-0" data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    
                    <div className="ms-auto d-none d-lg-block">
                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/img/pearl.png`} style={{height:"57px"}} alt="Icon" />

                        {/* <a href="" className="btn btn-primary rounded-pill py-2 px-3">Get A Quote</a> */}
                    </div>

                    <div className="collapse navbar-collapse " id="navbarCollapse" >
                        <div className="navbar-nav" style={{marginLeft:"auto"}}>
                            <NavLink to="/" className="nav-item nav-link" activeclassName="active">Home</NavLink>
                            <div className="nav-item dropdown">
                                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">About Us</a>
                                <div className="dropdown-menu bg-light rounded-0 rounded-bottom m-0">
                                    <NavLink to="/recruit" className="dropdown-item" activeclassName="active" onClick={handleClick}>Industries we recruit</NavLink>

                                    <NavLink to="/it-recruitment-solutions-in-india" className="dropdown-item" activeclassName="active" onClick={handleClick}>IT recruitment solutions</NavLink>

                                    <a href="#" id="da" className="dropdown-item">Our Offices</a>
                                    <span id="cc">
                                    <NavLink to="/placement-agency-in-chennai" className="dropdown-item" activeclassName="active" onClick={handleClick}>Placement agency in Chennai</NavLink>
                                    <NavLink to="/placement-agency-in-pune" className="dropdown-item" activeclassName="active" onClick={handleClick}>Placement agency in Pune</NavLink>

                                    </span>
                                            
                                        
                                    

                                </div>
                            </div>
                            <div className="nav-item dropdown">
                                <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Solutions</a>
                                <div className="dropdown-menu bg-light rounded-0 rounded-bottom m-0">

                                    <NavLink to="/hr-consulting-solutions-in-india" className="dropdown-item" activeclassName="active" onClick={handleClick}>HR Consulting Solutions</NavLink>
                                    <NavLink to="/it-consulting-solutions-india" className="dropdown-item" activeclassName="active" onClick={handleClick}>IT Consulting Solutions</NavLink>
                                  
                                </div>
                            </div>
                            <NavLink to={`/job-placements-in-india/${id}`} className="nav-item nav-link" activeclassName="active" onClick={handleClick}>Search Jobs</NavLink>
                            <NavLink to="/blogs-hr-it-consulting" className="nav-item nav-link" activeclassName="active" onClick={handleClick}>Blogs</NavLink>

                        <NavLink to="/contact-us" className="nav-item nav-link" activeclassName="active" onClick={handleClick}>Contact Us</NavLink>

                    </div>
                </div>
            </nav>
        </div>
    </div>
        </>
    )
}
export default Navbar