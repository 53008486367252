import React from "react";
import './blog.css'; 

function Itpune()
{
    return(
        <>

        <div className="py-5" style={{backgroundColor:"#2054ae"}}>
            <div className="container-fluid">
            
            <div className="row g-4">
               
                
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div style={{paddingTop:"40px"}}>
                       <h1 style={{color:"#ffffff",fontSize:"40px"}}>Consulting Manpower with Smart Minds</h1>
                       <p style={{color:"#ffffff",fontSize:"20px"}}>Our sincere interest and experience ensures that we are invested in hiring valuable resources or placements across Chennai the “Detroit of India”.</p>

                    </div>
                </div>
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div style={{float:"inline-end"}}>
                        <img className="img-fluid" src="img/chennai.jpg" alt="" />
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div className="py-5">
            <div className="container">

            <div className="row g-4">
                <div className="col-lg-12 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="tab-pane" id="2a" style={{marginTop:"50px"}}>
                        <div className="shake" style={{borderLeft:"4px solid #2054ae",marginTop:"30px",marginBottom:"30px"}}>
                            <h2 style={{marginLeft:"10px"}}>Attract your next best staff in pune</h2>
                        </div>
                        <h4 id="gggg">Recruiting simplified</h4>
                        <p>All you'll need from India's staffing company is here, including a best office team and an on-site recruitment service, to make your life easy. Working with us will provide you the assurance that candidates receive fair treatment and early payment, which will produce dependable and industrious candidates.</p>
                        <h4 id="gggg">Hands-on experience</h4>
                        <p>All of the consultants in our IT recruitment consultancy have hands-on experience with the work contexts for which we hire. By providing a service that is tailored to the needs of the consumer, they demonstrate a thorough understanding of their needs.</p>
                        <h4 id="gggg">Services tailored to your requirements</h4>
                        <p>We are aware that each customer has unique needs related to the goods and services they offer. Therefore, you may rely on us to modify our service to suit your requirements. We can help whether you're searching for a candidate for a specific employment position, a group of temporary employees, or experienced tradesmen for work on a short-notice contract.</p>
                    </div>
                          
                     

                </div>
                
               
            </div>
        </div>
        </div>


        <div className="py-5">
            <div className="container" style={{backgroundColor:"#f7fbff",padding:"30px"}}>
                <h2>Rapid Placement service and Specialization - With Local expertize</h2>
            <div className="row g-4">
                <div className="col-lg-12 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                <section id="section1">
        <div class="feedbacks accordion  bg-transparent rounded-5">
            <div class="accordion-item col-12  bg-transparent rounded-5">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed  bg-transparent text-dark rounded-5" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                  Specialized Knowledge

                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body text-dark">Because of our experience and genuine interest in each of our specialized areas, we are aware of the challenges our clients confront and the value they place on employing highly trained, motivated technical individuals. Our candidate advancement and business growth are fueled by our client-centric strategy. Thanks to our focused approach and specific knowledge, we can quickly find the right individuals for you.</div>
                </div>
              </div>
            
        </div>
 
        <div class="feedbacks accordion  bg-transparent rounded-5">
            <div class="accordion-item col-12  bg-transparent rounded-5 ">
                <h2 class="accordion-header" id="flush-headingTwo">
                  <button  class="accordion-button collapsed  bg-transparent text-dark rounded-5" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                  Rapid agility

                  </button>
                </h2>
                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body text-dark">Because we have access to one of the largest global networks of technical talent and have a strong online presence on all major job boards and social media platforms, we can deliver rapidly without sacrificing quality. From understanding your goals to finding the best candidates, we’re dedicated to giving you efficient assistance.</div>
                </div>
              </div>
        </div>
        <div class="feedbacks accordion  bg-transparent rounded-5">
            <div class="accordion-item col-12  bg-transparent rounded-5">
                <h2 class="accordion-header " id="flush-headingThree">
                  <button class="accordion-button collapsed  bg-transparent text-dark rounded-5" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    Placement in pune
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body text-dark">We can deliver quickly without sacrificing quality since we have access to one of the largest worldwide networks of technical talent and have a strong online presence on all major job boards and social media platforms. We’re committed to providing you with effective help, from comprehending your needs to identifying the most suitable applicants from any area localities of Pune. </div>
                </div>
              </div>
        </div>
    </section>
                          
                     

                </div>
                
               
            </div>
        </div>
        </div>



        <div className="py-5">
            <div className="container-fluid">
                <h2 className="text-center mb-5">Industries we hire for</h2>

            <div className="row g-4">
               
                
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="text-center" style={{backgroundColor:"#212529",padding:"30px",height:"350px"}}>
                        <h4 className="text-white">Healthcare Sector</h4>
                        <div>
                            <p className="text-white">We have experience working with companies in the healthcare industry, such as dentist offices, hospitals, insurance companies, and doctor's offices, whether you employ three people or a thousand. Look no further than Pearlcon if you're looking for thorough and efficient healthcare recruiters, all who can offer you the staffing solutions with leveraged hiring strategies and capabilities.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="text-center" style={{backgroundColor:"#212529",padding:"30px",height:"350px"}}>
                        <h4 className="text-white">Finance & Banking Sector</h4>
                        <div>
                            <p className="text-white">We developed our tech & touch solution for businesses that want to deliver value along with success. Our track record of success in locating the best people in the financial sector, including banking, insurance, accountancy, and non-bank financial companies, can assist you in identifying the ideal candidate to achieve your objectives.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="text-center" style={{backgroundColor:"#212529",padding:"30px",height:"350px"}}>
                        <h4 className="text-white">Information Technology Sector Staffing</h4>
                        <div>
                            <p className="text-white">We provide skilled and knowledgeable applicants sourced for IT/ software development, database , testing & many positions to companies all around the globe. Whether you want to recruit a deep learning-focused data scientist or an architect, we're ready for all IT job roles that are in demand complex to recruit within a time period.</p>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="row g-4" style={{marginTop:"1px"}}>
            <div className="col-lg-2 wow fadeInUp" data-wow-delay="0.7s"></div>

                
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="text-center" style={{backgroundColor:"#212529",padding:"30px",height:"350px"}}>
                        <h4 className="text-white">Industrial & Manufacturing Sector</h4>
                        <div>
                            <p className="text-white">Our HR specialists assist you in selecting Industrial & Manufacturing candidates that are qualified and fit for your company, but they also work to provide the most accurate Industrial recruitment process possible, matching your needs each and every time. Our professionals are skilled at adhering to the most recent guidelines for the manufacturing workforce and its sector.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="text-center" style={{backgroundColor:"#212529",padding:"30px",height:"350px"}}>
                        <h4 className="text-white">Logistics & Warehouse Hiring</h4>
                        <div>
                            <p className="text-white">We are constantly searching for warehouse workers to suit the recruitment demands of our clients, as many large high street and online retailers are growing their workforces to keep up with demand. Pickers and packers, managers, and warehouse drivers are typical positions for which we may hire candidates. Purchasing, production planning, inventory control, warehouse management, freight coordination, sourcing, contract management, etc. are some of the areas.</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-2 wow fadeInUp" data-wow-delay="0.7s"></div>

              
            </div>
        </div>
        </div>




  
        </>
    )
}
export default Itpune;