import React from 'react';
import './bootstrap.min.css'; 
import './joke.css'; 
import { NavLink } from 'react-router-dom';

function Footer(){
    // const [activeLink, setActiveLink] = React.useState('');
    const [activeLink, setActiveLink] = React.useState('/');
    const handleClick = () => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };

    return (
        <>
        
        <div className="container-fluid bg-dark footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container py-5">
            <div className="row g-5">
            <div className="col-lg-3 col-md-6">
            <img src={`${process.env.PUBLIC_URL}/img/pearl.png`} alt="My Image" />


                    <div className="position-relative w-100 ">
                        <p>Experienced professionals are waiting to make a difference in your organisation. Get in touch with us to find your next great hire.</p>
                    </div>
                </div>
                
                <div className="col-lg-2 col-md-6">
                    <h4 className="text-white mb-4">Quick Links</h4>
                    <div className="footer">
                    <NavLink exact to="/" className="h4 btn btn-link" activeClassName="active" onClick={() => { setActiveLink('/'); handleClick(); }}>
                        Home
                    </NavLink>
                    <NavLink to="/blogs-hr-it-consulting" className="h4 btn btn-link" activeClassName="active" onClick={() => { setActiveLink('/blog'); handleClick(); }}>
                        Blogs
                    </NavLink>
                    <NavLink to="/about" className="h4 btn btn-link" activeClassName="active" onClick={() => { setActiveLink('/about'); handleClick(); }}>
                        About Us
                    </NavLink>
                     <NavLink to="/contact-us" className="h4 btn btn-link" activeClassName="active" onClick={() => { setActiveLink('/contact'); handleClick(); }}>
                        Contact Us
                    </NavLink>
                </div>

                  
                   
                </div>
                <div className="col-lg-3 col-md-6">
                    <h4 className="text-white mb-4">Business Hours</h4>
                    <p className="mb-1">Monday - Friday</p>
                    <h6 className="text-light">09:30 am - 06:30 pm</h6>
                    <p className="mb-1">Saturday</p>
                    <h6 className="text-light">09:30 am - 05:30 pm</h6>
                    <p className="mb-1">Sunday</p>
                    <h6 className="text-light">Closed</h6>
                </div>

                <div className="col-lg-4 col-md-6">
                    <h4 className="text-white mb-4">Our Office</h4>
                    <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>Chennai and Pune</p>
                    <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>9960662615</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3"></i>opportunities@pearlcons.com</p>
                    <div className="d-flex pt-3">
                        <a className="btn btn-square btn-light rounded-circle me-2" href="https://twitter.com/Pearlcontech" target="_blank"><i
                                className="fab fa-twitter"></i></a>
                        <a className="btn btn-square btn-light rounded-circle me-2" href="https://www.facebook.com/pearlcontechnologies" target="_blank"><i
                                className="fab fa-facebook-f"></i></a>
                        <a className="btn btn-square btn-light rounded-circle me-2" href="https://www.instagram.com/pearlcon.tech/" target="_blank"><i
                                className="fab fa-instagram"></i></a>
                        <a className="btn btn-square btn-light rounded-circle me-2" href="https://in.linkedin.com/company/pearlcontechnologies" target="_blank"><i
                                className="fab fa-linkedin-in"></i></a>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
   
    <div className="container-fluid copyright py-4">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center  mb-3 mb-md-0">
                    &copy; <a className="fw-medium text-light" href="#">2023, Pearlcon Technologies</a>, All rights Reserved.
                </div>
                {/* <div className="col-md-6 text-center text-md-end">
                    Designed By <a className="fw-medium text-light" href="https://htmlcodex.com">Pearl</a>
                </div> */}
            </div>
        </div>
    </div>

        </>
    )
}
export default Footer;