import React, { useState, useEffect } from 'react';

import './blog.css'; 
import { Link } from 'react-router-dom';

function Blog()
{
  
  useEffect(() => {
    document.title = "Latest Blogs & Insights in Staffing | HR | IT consulting solutions";
  }, []);



  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetchBlogData();
  }, []);

  const fetchBlogData = async () => {
    try {
      const response = await fetch('https://www.pearlcons.com/hrms/pearlchrms/api/blocks');
      // const response = await fetch('http://127.0.0.1:8000/api/blocks');

      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const data = await response.json();
      setBlogs(data.blogs);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
    return(
        <>
       

        <div className="py-5" style={{backgroundColor:"#2054ae"}}>
        <div className="container-fluid">
            
            <div className="row g-4">
               
                
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div style={{paddingTop:"70px"}}>
                       <h1 style={{color:"#ffffff",fontSize:"40px"}}>Blogs to Read</h1>
                       <p style={{color:"#ffffff",fontSize:"20px"}}>Get updated with current scenario, recent trends , hiring approaches followed by professionals & companies in HR & IT industry</p>
                       
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div style={{float:"inline-end"}}>
                        <img className="img-fluid" src="img/blog.jpg" alt="" />
                       
                    </div>
                </div>
            </div>
        </div>
        </div>




    <div className="container-xxl py-5">
        <div className="container-fluid">
        <h3 className="mb-4 text-center">Our Blogs</h3>

            <div className="row">
            {blogs.map(blog => (

            <div  key={blog.id} className="col-lg-4 wow fadeInUp d-flex align-items-center justify-content-center" data-wow-delay="0.1s">
                <div className="card">
                  <div className="card__header">
                  {blog.file_url && <img src={blog.file_url} alt={blog.post_title} className="card__image" width="600" height="200" />}
                    {/* <img src="img/blog1.jpg" alt="card__image" className="card__image" width="600" height="200" /> */}
                  </div>
                  <div className="card__body" style={{height:"200px"}}>
                    {/* <span className="tag tag-blue">Technology</span> */}
                    <h4>{blog.post_title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: (new DOMParser().parseFromString(blog.post_content, 'text/html').body.textContent || '').slice(0, 150) + '...' }}></p>
                    </div>
                  <div className="card__footer">

                    <div className="user">
                    <Link  className="btn btn-primary" to={{pathname: `/view/${blog.id}`}}>Read More</Link>


                    </div>
                  </div>
                </div>
              

            
            
            </div>
          ))}
          </div>
        </div>
    </div>

        </>
    )
}
export default Blog;