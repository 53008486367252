import React, { useState, useEffect } from 'react';

import { Link,useParams,useLocation  } from 'react-router-dom';



const Viewblog = () => {
    const { id } = useParams();
    const location = useLocation();

    console.log("ID:", id);
    // Define state variables to store blog data
    const [title, setTitle] = useState('');
    const [image, setImage] = useState('');
    const [content, setContent] = useState('');

    useEffect(() => {
        const fetchBlogData = async () => {
        try {
            // const response = await fetch(`http://127.0.0.1:8000/api/blocks/${id}`);
            const response = await fetch(`https://www.pearlcons.com/hrms/pearlchrms/api/blocks/${id}`);

            if (!response.ok) {
            throw new Error('Failed to fetch data');
            }
            const data = await response.json();
            // Update state with fetched data

            setTitle(data.blog.post_title);
            setImage(data.blog.file_url);
            setContent(data.blog.post_content); 
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchBlogData(); // Call the fetch function when the component mounts or id changes
    }, [id]); // Dependency array to re-fetch data when id changes

 
    return(
        <>

        <div className="py-5" style={{backgroundColor:"#2054ae"}}>
        <div className="container-fluid">
            
            <div className="row g-4">
               
                
                <div className="col-lg-5 col-md-5 wow fadeInUp" data-wow-delay="0.5s">
                            <div style={{margin:"inherit"}}>
                                <h1 className="text-white">{title}</h1>
                            </div>
                           
                            {/* <div>
                                <p className="text-white text-center" dangerouslySetInnerHTML={{ __html: (new DOMParser().parseFromString(content, 'text/html').body.textContent || '') }}></p>
                            </div> */}
                          
                </div>
                <div className="col-lg-7 col-md-7 wow fadeInUp" data-wow-delay="0.5s">
                <div style={{display:"ruby-text"}}>
                                <img src={image} className="img-fluid mb-4" style={{height:"400px",width:"100%"}} />
                 </div>
                </div>

              
            </div>
            

        </div>
        </div>


        
        <div className="py-5">
        <div className="container-fluid">
            
            <div className="row g-4">
               
                
                

              
            </div>
            <div className="row g-4">
            <p  dangerouslySetInnerHTML={{ __html: (new DOMParser().parseFromString(content, 'text/html').body.textContent || '') }}></p>

                </div>

        </div>
        </div>




    
        </>
    )
}
export default Viewblog;