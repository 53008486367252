import React from "react";
import './blog.css'; 

function Recruitment()
{

    return(
        <>

        <div className="py-5" style={{backgroundColor:"#2054ae"}}>
        <div className="container-fluid">
            
            <div className="row g-4">
               
                
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div style={{paddingTop:"70px"}}>
                       <h1 style={{color:"#ffffff",fontSize:"40px"}}>Eminent Hirings for All Industries</h1>
                       <p style={{color:"#ffffff",fontSize:"20px"}}>For nearly 5+ years, Pearlcon has been eminently involved in the matching of qualified applicants with open positions in India & globe.</p>
                       <p style={{color:"#ffffff",fontSize:"20px"}}>Although we have assisted companies across numerous industries, these are our areas of expertise.</p>

                    </div>
                </div>
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div style={{float:"inline-end"}}>
                        <img className="img-fluid" src="img/recruit.png" alt="" />
                       
                    </div>
                </div>
            </div>
        </div>
        </div>




    <div className="container-xxl py-5">
        <div className="container-fluid">
        <h3 className="mb-4 text-center">Diverse Hirings In Diverse Sectors</h3>
        <p className="mb-4 text-center">Here are the significant sectors that we have worked across many locations and needs</p>


            
        <div class="row"style={{paddingTop:"20px"}}>
            <div className="col-lg-4 mx-auto">
                    <blockquote style={{height:"500px"}} className="blockquote blockquote-custom bg-white p-5 shadow rounded">
                        <div className="jeen">
                            <div className="blockquote-custom-icon bg-info shadow-sm"><i class="fa fa-quote-left text-white"></i></div>
                            <div><h3>IT /TECH Recruiting</h3></div>
                            <p className="mb-0 mt-2 font-italic">We provide skilled and knowledgeable applicants sourced for IT/ software development, database , testing & many positions to companies all around the globe. Whether you want to recruit a deep learning-focused data scientist or an engineer to pioneer industrial automation, we're ready for all IT job roles that are in demand and complex to recruit within a time period.</p>
                        </div>
                    </blockquote>

                <blockquote style={{height:"600px",marginTop:"50px"}} className="blockquote blockquote-custom bg-white p-5 shadow rounded">
                    <div className="jeen">  
                        <div className="blockquote-custom-icon bg-info shadow-sm"><i class="fa fa-quote-left text-white"></i></div>
                        <div><h3>Industrial & Manufacturing Sector</h3></div>
                        <p className="mb-0 mt-2 font-italic">Our recruiters assist you in selecting Industrial staffs who are qualified and fit for your company, where our staff hiring includes functions like, operations, production, body shop, maintenance, mechanical engineering, sales and service, and more other significant ones. We consider the company's structure and develop position specs for recruiting the competent candidates available & matching your work culture.</p>
                    </div>
                </blockquote>

            </div>

            <div className="col-lg-4 mx-auto">

                <blockquote style={{height:"500px"}} className="blockquote jeen blockquote-custom bg-white p-5 shadow rounded">
                    <div className="jeen">      
                        <div className="blockquote-custom-icon bg-info shadow-sm"><i class="fa fa-quote-left text-white"></i></div>
                        <div><h3>Finance & Banking Sector</h3></div>
                        <p className="mb-0 mt-2 font-italic">We developed our tech & touch solution for businesses that want to deliver value along with success. Our track record of success in locating the best people in the financial sector, including banking, insurance, accountancy, and non-bank financial companies, can assist you in identifying the ideal candidate to achieve your objectives.</p>
                      </div>  
                </blockquote>

                <blockquote style={{height:"600px",marginTop:"50px"}} className="blockquote blockquote-custom bg-white p-5 shadow rounded">
                    <div className="jeen">  
                        <div className="blockquote-custom-icon bg-info shadow-sm"><i class="fa fa-quote-left text-white"></i></div>
                        <div><h3>Logistics & Warehouse Sector</h3></div>
                        <p className="mb-0 mt-2 font-italic">We are constantly hiring for warehouse workers to suit the recruitment demands of our clients, as many large high street and online retailers are growing their workforces to keep up with demand. Pickers and packers, managers, and warehouse drivers are typical positions for which we hire candidates. Purchasing, production planning, inventory &, warehouse management, freight coordination, contract management, etc. are also concentrated by our team</p>
                    </div>
                </blockquote>

            </div>

            <div className="col-lg-4 mx-auto">

                <blockquote style={{height:"500px"}} className="blockquote blockquote-custom bg-white p-5 shadow rounded">
                    <div className="jeen">  
                        <div className="blockquote-custom-icon bg-info shadow-sm"><i class="fa fa-quote-left text-white"></i></div>
                        <div><h3>Healthcare Sector</h3></div>
                        <p className="mb-0 mt-2 font-italic">We have experience working with companies in the healthcare industry, such as dentist offices, hospitals, insurance companies, and doctor's offices, whether you employ three people or a thousand. We are aware that the healthcare sector demands a certain skill set, and we are skilled at assessing applicants to find the most qualified individuals for the roles you are looking to fill.</p>
                    </div>
                </blockquote>

            </div>
        </div>
    </div>
    </div>
        </>
    )
}
export default Recruitment;