import React from "react";
import { useEffect } from "react";
import './bootstrap.min.css'; 
import './joke.css'; 
function Contact()
{

    useEffect(() => {
        document.title = "Contact Pearlcon HR services |Efficient Job consulting India";
        document.querySelector('meta[name="description"]').setAttribute('content', "If you have any queries regarding Pearlcon's HR services, for any staffing and job consulting, Feel free reach out to us at opportunities@pearlcons.com");
      }, []);
    return(
        <>

        <div className="py-5" style={{backgroundColor:"#2054ae"}}>
        <div className="container-fluid">
            
            <div className="row g-4">
               
                
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div>
                       <h1 style={{color:"#ffffff",fontSize:"40px"}}>Contact Us</h1>
                       <p style={{color:"#ffffff",fontSize:"15px"}}>Pearlcon is a member of a worldwide network of workforce development companies. Regardless of your location, we're readily accessible. Interested in enhancing your workforce and operational efficiencies? Let's have a conversation.</p>
                        <p style={{color:"#ffffff",fontSize:"15px"}}>Reminder: Our services for job seekers are entirely free.</p>
                        <p style={{color:"#ffffff",fontSize:"15px"}}>Please be aware that Pearlcon will never, under any circumstances, request job seekers to pay for our recruiting services.</p>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div style={{float:"inline-end"}}>
                        <img className="img-fluid" src="img/contact.png" alt="" />
                       
                    </div>
                </div>
            </div>
        </div>
        </div>




    <div className="container-xxl py-5">
        <div className="container">
            <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: "500px"}}>
                <p className="fs-5 fw-medium text-primary">Contact Us</p>
                <h1 className="display-5 mb-5">If You Have Any Query, Please Contact Us</h1>
            </div>
            <div className="row g-5">
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                    <h3 className="mb-4">Need to Improve your business?</h3>
                    <p className="mb-4">To discover our caliber, kindly send your valuable  requirement and embark on your journey towards recruitment fulfillment.</p>
                    <form>
                        <div className="row g-3">
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                    <label for="name">Your Name</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input type="email" className="form-control" id="email" placeholder="Your Email" />
                                    <label for="email">Your Company</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="name" placeholder="Your Name" />
                                    <label for="name">Your Mobile</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-floating">
                                    <input type="email" className="form-control" id="email" placeholder="Your Email" />
                                    <label for="email">Your Email</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-floating">
                                    <select className="form-select" id="service">
                                        <option selected>IT</option>
                                        <option value="">Non-IT</option>
                                        
                                    </select>
                                    <label for="service">Select a Industry</label>
                                    
                                </div>
                            </div>
                            
                            <div className="col-12">
                                <div className="form-floating">
                                    <input type="text" className="form-control" id="subject" placeholder="Subject" />
                                    <label for="subject">Subject</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-floating">
                                    <textarea className="form-control" placeholder="Leave a message here" id="message" style={{height: "200px"}}></textarea>
                                    <label for="message">Message</label>
                                </div>
                            </div>
                            <div className="col-12">
                                <button className="btn btn-primary rounded-pill py-3 px-5" type="submit">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                    <h3 className="mb-4">Contact Details</h3>
                    <div className="d-flex border-bottom pb-3 mb-3">
                        <div className="flex-shrink-0 btn-square bg-primary rounded-circle">
                            <i className="fa fa-map-marker-alt text-white"></i>
                        </div>
                        <div className="ms-3">
                            <h6>Our Office</h6>
                            <span>#50B, Kamarajar salai, Ashok nagar, Chennai</span>
                        </div>
                    </div>
                    <div className="d-flex border-bottom pb-3 mb-3">
                        <div className="flex-shrink-0 btn-square bg-primary rounded-circle">
                            <i className="fa fa-phone-alt text-white"></i>
                        </div>
                        <div className="ms-3">
                            <h6>Call Us</h6>
                            <span>9960662615</span>
                        </div>
                    </div>
                    <div className="d-flex border-bottom-0 pb-3 mb-3">
                        <div className="flex-shrink-0 btn-square bg-primary rounded-circle">
                            <i className="fa fa-envelope text-white"></i>
                        </div>
                        <div className="ms-3">
                            <h6>Mail Us</h6>
                            <span>opportunities@pearlcons.com</span>
                        </div>
                    </div>
                    <iframe className="w-100 rounded"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15547.73237569042!2d80.2087636!3d13.0399307!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267502c6c619f%3A0x2c7113fc9a16d79a!2sPearlcon%20Technologies%20-%20Placement%20consultant%20%26%20IT%20solutions!5e0!3m2!1sen!2sin!4v1712664119502!5m2!1sen!2sin"
                    frameborder="0" style={{minHeight: "300px", border:"0"}} allowfullscreen="" aria-hidden="false"
                    tabindex="0"></iframe>
                </div>
            </div>
        </div>
    </div>

        </>
    )
}
export default Contact;