import React from "react";
import './blog.css'; 
import { useEffect } from "react";
import './bootstrap.min.css'; 
import './joke.css'; 

function Itchennai()
{

    useEffect(()=>{
        document.title = "Smart Placement agency in chennai for Smart manpower |Pearlcon"
    })
    return(
        <>

        <div className="py-5" style={{backgroundColor:"#2054ae"}}>
            <div className="container-fluid">
            
            <div className="row g-4">
               
                
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div style={{paddingTop:"40px"}}>
                       <h1 style={{color:"#ffffff",fontSize:"40px"}}>Consulting Manpower with Smart Minds</h1>
                       <p style={{color:"#ffffff",fontSize:"20px"}}>Our sincere interest and experience ensures that we are invested in hiring valuable resources or placements across Chennai the “Detroit of India”.</p>

                    </div>
                </div>
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div style={{float:"inline-end"}}>
                        <img className="img-fluid" src="img/chennai.jpg" alt="" />
                       
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div className="py-5">
            <div className="container">
            <h3 className="mb-4 text-center text3">Placement Service, Speed and Specialised knowledge - With Local expertise</h3>

            <div className="row g-4">
                <div className="col-lg-12 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                        <ul  className="nav nav-pills text-center justify-content-center" id="rrrr" style={{marginTop:"30px"}}>
                            <li class="active">
                                <a  href="#1a" id="aa1" data-toggle="tab">Specialised Knowledge</a>
                            </li>
                            <li>
                                <a href="#2a" id="aa1" data-toggle="tab">Rapid agility</a>
                            </li>
                            <li>
                                <a href="#3a" id="aa1" data-toggle="tab">Placement services in chennai</a>
                            </li>
                            
                        </ul>

                            <div className="tab-content clearfix">
                                <div className="tab-pane active bb1" id="1a" style={{marginTop:"50px"}}>
                                    <p>We comprehend the difficulties our clients face and the importance they place on hiring highly qualified, driven technical personnel because of our experience and sincere interest in each of our specialty areas.</p>
                                    <p>Our client-centric approach is what drives business growth and elevates candidate experience. With our targeted approach and specialised knowledge, we can locate the ideal candidates for you quickly and cost-effectively.</p>
                                    <div className="shake" style={{borderLeft:"4px solid #2054ae",marginTop:"30px",marginBottom:"30px"}}>
                                        <h2 style={{marginLeft:"10px"}}>Attract your next best staff in chennai</h2>
                                    </div>
                                    <h4 id="gggg">Recruiting simplified</h4>
                                    <p>All you'll need from India's staffing company is here, including a best office team and an on-site recruitment service, to make your life easy. Working with us will provide you the assurance that candidates receive fair treatment and early payment, which will produce dependable and industrious candidates.</p>
                                    <h4 id="gggg">Hands-on experience</h4>
                                    <p>All of the consultants in our IT recruitment consultancy have hands-on experience with the work contexts for which we hire. By providing a service that is tailored to the needs of the consumer, they demonstrate a thorough understanding of their needs.</p>
                                    <h4 id="gggg">Services tailored to your requirements</h4>
                                    <p>We are aware that each customer has unique needs related to the goods and services they offer. Therefore, you may rely on us to modify our service to suit your requirements. We can help whether you're searching for a candidate for a specific employment position, a group of temporary employees, or experienced tradesmen for work on a short-notice contract.</p>
                                </div>
                                <div className="tab-pane" id="2a" style={{marginTop:"50px"}}>
                                    <p>We can deliver quickly without sacrificing quality since we have access to one of the largest worldwide networks of technical talent and have a strong online presence on all major job boards and social media platforms. We’re committed to providing you with effective help, from comprehending your needs to identifying the most suitable applicants.</p>
                                    <div className="shake" style={{borderLeft:"4px solid #2054ae",marginTop:"30px",marginBottom:"30px"}}>
                                        <h2 style={{marginLeft:"10px"}}>Attract your next best staff in chennai</h2>
                                    </div>
                                    <h4 id="gggg">Recruiting simplified</h4>
                                    <p>All you'll need from India's staffing company is here, including a best office team and an on-site recruitment service, to make your life easy. Working with us will provide you the assurance that candidates receive fair treatment and early payment, which will produce dependable and industrious candidates.</p>
                                    <h4 id="gggg">Hands-on experience</h4>
                                    <p>All of the consultants in our IT recruitment consultancy have hands-on experience with the work contexts for which we hire. By providing a service that is tailored to the needs of the consumer, they demonstrate a thorough understanding of their needs.</p>
                                    <h4 id="gggg">Services tailored to your requirements</h4>
                                    <p>We are aware that each customer has unique needs related to the goods and services they offer. Therefore, you may rely on us to modify our service to suit your requirements. We can help whether you're searching for a candidate for a specific employment position, a group of temporary employees, or experienced tradesmen for work on a short-notice contract.</p>
                                </div>
                                <div className="tab-pane" id="3a" style={{marginTop:"50px"}}>
                                    <p>Each of our well- qualified technical recruiters team is equipped to provide first-rate service, and you will be looked after by a dedicated Account Manager who is assisted by a multi-skilled team. Whether you’re looking for large volume contract resources for a short deadline or a specialist function that’s difficult to fill, we customise our strategy to meet your unique needs in the Metropolitan city of Chennai.</p>
                                    <div className="shake" style={{borderLeft:"4px solid #2054ae",marginTop:"30px",marginBottom:"30px"}}>
                                        <h2 style={{marginLeft:"10px"}}>Attract your next best staff in chennai</h2>
                                    </div>
                                    <h4 id="gggg">Recruiting simplified</h4>
                                    <p>All you'll need from India's staffing company is here, including a best office team and an on-site recruitment service, to make your life easy. Working with us will provide you the assurance that candidates receive fair treatment and early payment, which will produce dependable and industrious candidates.</p>
                                    <h4 id="gggg">Hands-on experience</h4>
                                    <p>All of the consultants in our IT recruitment consultancy have hands-on experience with the work contexts for which we hire. By providing a service that is tailored to the needs of the consumer, they demonstrate a thorough understanding of their needs.</p>
                                    <h4 id="gggg">Services tailored to your requirements</h4>
                                    <p>We are aware that each customer has unique needs related to the goods and services they offer. Therefore, you may rely on us to modify our service to suit your requirements. We can help whether you're searching for a candidate for a specific employment position, a group of temporary employees, or experienced tradesmen for work on a short-notice contract.</p>
                                </div>
                     
                            </div>

                </div>
                
               
            </div>
        </div>
        </div>



        <div className="py-5">
            <div className="container-fluid">
                <h2 className="text-center mb-5">Industries we hire for</h2>

            <div className="row g-4">
               
                
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="text-center" style={{backgroundColor:"#212529",padding:"30px",height:"350px"}}>
                        <h4 className="text-white">Healthcare Sector</h4>
                        <div>
                            <p className="text-white">We have experience working with companies in chennai with respect to healthcare industry, such as dentist offices, hospitals, insurance companies, and doctor's offices, whether you employ three people or a thousand. We are aware that the healthcare sector demands a certain skill set, and we are skilled at assessing applicants to find the most qualified individuals for the roles you are looking to fill.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="text-center" style={{backgroundColor:"#212529",padding:"30px",height:"350px"}}>
                        <h4 className="text-white">Finance & Banking Sector</h4>
                        <div>
                            <p className="text-white">We developed our tech & touch solution for businesses that want to deliver value along with success. Our track record of success in locating the best people in the financial sector, including banking, insurance, accountancy, and non-bank financial companies, can assist you in identifying the ideal candidate to achieve your objectives.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="text-center" style={{backgroundColor:"#212529",padding:"30px",height:"350px"}}>
                        <h4 className="text-white">Logistics & Warehouse Sector</h4>
                        <div>
                            <p className="text-white">We are constantly hiring warehouse workers to suit the recruitment demands of our clients, as many large high street and online retailers are growing their workforces to keep up with demand. Pickers and packers, managers, and warehouse drivers are typical positions for which we hire candidates.</p>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="row g-4" style={{marginTop:"1px"}}>
            <div className="col-lg-2 wow fadeInUp" data-wow-delay="0.7s"></div>

                
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="text-center" style={{backgroundColor:"#212529",padding:"30px",height:"350px"}}>
                        <h4 className="text-white">Industrial & Manufacturing Sector</h4>
                        <div>
                            <p className="text-white">Industrial & Manufacturing Sector
                                        Our recruiters assist you in selecting Industrial staffs who are qualified and fit for your company, where our staff hiring includes functions like, operations, production, body shop, maintenance, mechanical engineering, sales and service, and more other significant ones. We consider the company's structure and develop position specs for recruiting the competent candidates available & matching your work culture.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="text-center" style={{backgroundColor:"#212529",padding:"30px",height:"350px"}}>
                        <h4 className="text-white">Information Technology Sector</h4>
                        <div>
                            <p className="text-white">Pearlcon helps companies find qualified applicants for both contract and permanent IT and technology roles. Our IT recruitment firm concentrates on the unique requirements of your business and the information technology sector while conducting incredibly rigorous recruitment procedures. Our extensive database of qualified IT staffs is pre-screened based on their technical skill sets.</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-2 wow fadeInUp" data-wow-delay="0.7s"></div>

              
            </div>
        </div>
        </div>




  
        </>
    )
}
export default Itchennai;