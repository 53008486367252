import React from "react";
import './blog.css'; 


function Itsolution()
{
  
    return(
        <>

        <div className="py-5" style={{backgroundColor:"#2054ae"}}>
            <div className="container-fluid">
            
            <div className="row g-4">
               
                
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div style={{paddingTop:"40px"}}>
                       <h1 style={{color:"#ffffff",fontSize:"40px"}}>Customized IT consulting services</h1>
                       <p style={{color:"#ffffff",fontSize:"20px"}}>Capitalize on market demands and be flexible to provide leveraged digital experience to your customers, so reach us for efficient IT/software services. </p>

                    </div>
                </div>
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <div style={{float:"inline-end"}}>
                        <img className="img-fluid" src="img/why_new1.png" style={{height:"550px",width:"100%"}} alt="" />
                       
                    </div>
                </div>
            </div>
        </div>
        </div>

        <div className="py-5">
            <div className="container-fluid" >

            <div className="row g-4">
                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                    <img className="img-fluid" src="img/it_solutions.jpg" style={{height:"550px",width:"100%"}}  alt="" />

                </div>

                <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                    <div>
                        <h1>We are focused on IT solutions convenient for you</h1>
                    </div>
                    <div>
                        <p>At Pearlcon, we re-invent corporate potential by fusing the power of enterprise, digital, and domain technology at your convenient time & costs. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Hence we have clarity on your goals and work towards the proper direction for consistent growth of MSME's.</p>
                    </div>
                    <div style={{marginTop:"50px"}}>
                        <div className="row g-4">
                            <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                                <div className="row g-4">
                                    <div className="col-lg-3 wow fadeInUp" data-wow-delay="0.7s">
                                        <img className="img-fluid" src="img/repair-expert.png" alt="" />

                                    </div>
                                    <div className="col-lg-9 wow fadeInUp" data-wow-delay="0.7s">
                                        <h4 className="text-end">Flexible Team</h4>
                                        <p className="text-end">Our team is flexible to deliver the appropriate skills for product development, consumer interaction, and digital consultancy.</p>
                                    </div>
                                </div>
                                    
                            </div>
                            <div className="col-lg-6  wow fadeInUp" data-wow-delay="0.7s">
                                <div className="row g-4">
                                    <div className="col-lg-3 wow fadeInUp" data-wow-delay="0.7s">
                                        <img className="img-fluid" src="img/bar-chart.png" alt="" />

                                    </div>
                                    <div className="col-lg-9 wow fadeInUp" data-wow-delay="0.7s">
                                        <h4 className="text-end">Business Growth</h4>
                                        <p className="text-end">We handle software system integration, development support, and ongoing enhancements for effective corporate expansion.</p>
                                    </div>
                                </div>
                            </div>

                    </div>
                    </div>
                </div>
                
               
            </div>
        </div>
        </div>



        <div className="py-5" style={{backgroundColor:"#f4f9fe"}}>
            <div className="container-fluid">
                <h2 className="text-center mb-5">Our Various Leveraged IT solutions for any Business</h2>

            <div className="row g-4">
               
                
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{height:"340px"}}>
                        <div>
                            <img className="img-fluid" src="img/design.png" style={{height:"80px"}}  alt="" />
                        </div>
                        <div className="my-3">
                            <h4>CMS Design & Development</h4>
                        </div>
                        <div>
                            <p>With our CMS solutions, creating, optimizing, and managing material in real time is simple and improves customer experience. We offer a consistent user experience across devices by using responsive and adaptive CMS web design. With a user-friendly interface that can quickly adjust to changing needs.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{height:"340px"}}>
                        <div>
                            <img className="img-fluid" src="img/front-development.png" style={{height:"80px"}}  alt="" />
                        </div>
                        <div className="my-3">
                            <h4>E-Commerce & Web-Apps</h4>
                        </div>
                        <div>
                            <p>We offer lightweight E-commerce solutions with reduced load time to increase the speed for better usability. Our solution provides multi-store function to manage different stores through unified admin. We enable them through web and native app platforms</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{height:"340px"}}>
                        <div>
                            <img className="img-fluid" src="img/desktop-1.png" style={{height:"80px"}}  alt="" />
                        </div>
                        <div className="my-3">
                            <h4>Cloud Migration Service</h4>
                        </div>
                        <div>
                            <p>We help our clients move their applications and data to the most effective IT environment for Lower cost, better performance and high security. In addition, we ensure the key benefits of redundancy, elasticity, provisioning and most importantly pay-per-use choice.</p>
                        </div>
                    </div>
                </div>
            </div>

            
            <div className="row g-4">
            <div className="col-lg-2 wow fadeInUp" data-wow-delay="0.7s"></div>

                
                <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                    <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{height:"340px"}}>
                        <div>
                            <img className="img-fluid" src="img/ai.png" style={{height:"80px"}}  alt="" />
                        </div>
                        <div className="my-3">
                            <h4>AI & Full Stack Development</h4>
                        </div>
                        <div>
                            <p>Our Leveraged AI and full stack development facilitates various solutions for clients requiring simple as well as complex needs in business. Our approach is transparent and clearly updated through periodic mails via automated workflows.</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.7s">
                    <div className="shadow-lg p-3 mb-5 bg-body rounded" style={{height:"340px"}}>
                        <div>
                            <img className="img-fluid" src="img/graphic-board.png" style={{height:"80px"}}  alt="" />
                        </div>
                        <div className="my-3">
                            <h4>IT Automation</h4>
                        </div>
                        <div>
                            <p>Our IT Automation solutions has several benefits, such as reduced errors, operational efficiency, time and cost savings, etc. Our product portfolios, which include HRMS, ERP, CRM, MIS, and others, assist us in achieving these goals.</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-2 wow fadeInUp" data-wow-delay="0.7s"></div>

              
            </div>
        </div>
        </div>




  
        </>
    )
}
export default Itsolution;